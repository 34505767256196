.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #101115;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

div {
  font-family: "Poppins", sans-serif !important;
  color: #fff !important;
}

p {
  padding: 0 3rem;
  margin: 0.5em 0 2em 0;
  color: #f2c94c;
  font-size: 0.7em;
  max-width: 1000px;
}

button {
  border: 0;
  border-radius: 25px;
  background-color: #f2c94c;
  color: #000;
  padding: 1em 2em;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

input {
  border: 0;
  padding: 1em;
  border-radius: 25px;
  margin: 1em 1em 0 0;
  font-family: "Poppins", sans-serif;
  min-width: 200px;
  outline: 0;
}

.socials {
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

svg.social-svg {
  padding-right: 20px;
}

svg.social-svg:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 700px) {
  .App-logo {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 400px) {
  .App-logo {
    width: 100%;
    height: auto;
  }
  button {
    margin: 1em 0 0 0;
  }
  .App-header {
    align-items: none;
  }
  p {
    padding: 0 1em;
  }
}
